.wrapper {
    --product__layout-padding: 16px;
    background-color: var(--color-grey-01);
    padding: var(--product__layout-padding);

    @media (--from-xsmall) {
        --product__layout-padding: 24px;
    }

    @media (--from-large) {
        --product__layout-padding: 56px;
    }

    .properties {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1em;
        page-break-inside: avoid;

        @media (--to-medium) {
            grid-template-columns: 1fr;
            gap: 0;

            .block {
                padding: 16px 0;
                border-bottom: 1px solid var(--color-grey-02);

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}

.properties-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 24px;
    color: var(--color-oxford);

    @media (--to-medium) {
        gap: 8px;

        .horizontal & {
            flex-direction: row;
            justify-content: space-around;
            gap: 0;

            .properties-item {
                flex: 1 1 0;
                grid-template-columns: 1fr;
                gap: 8px;
                justify-items: center;
            }
        }
    }

    .properties-item {
        display: grid;
        grid-template-columns: 26px 1fr;
        align-items: center;
        gap: 12px;
    }
}

.label {
    font-size: 13px;
    letter-spacing: 0.2px;

    .horizontal & {
        font-weight: bold;
        text-transform: uppercase;
    }
}

.content-item {
    font-size: 13px;
    line-height: 150%;
    letter-spacing: 0.2px;

    & strong {
        font-weight: bold;
    }

    & span {
        font-weight: normal;

        @media (--from-medium) {
            text-transform: lowercase;
        }
    }
}

.style-label {
    composes: label;
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
}

.style-button {
    appearance: none;
    background: var(--color-mint-green);
    padding: 0;
    display: flex;
    border-radius: 50%;

    &:hover {
        background: var(--color-chinook);
    }
}

.style-popover {
    width: 560px;
    font-weight: normal;
    padding: 16px;
    position: absolute;
    z-index: 1;
    background-color: white;
    color: var(--color-swamp);
    border: 1px solid var(--color-iron);
    border-radius: 4px;
    top: calc(100% + 4px);
    right: 0;
    display: grid;
    gap: 24px;
    grid-template-columns: 263px 1fr;
    box-shadow: 0 0 10px #0002;

    @media (--to-small) {
        width: auto;
        grid-template-columns: 263px;
    }

    h3 {
        font-family: inherit;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        margin: 0 0 8px 0;
    }

    h2 {
        font-family: var(--font-family-serif);
        font-size: 20px;
        font-weight: bold;
        margin: 0 0 24px 0;
        text-align: center;
    }

    p {
        color: inherit;
        margin: 0;
        font-size: 14px;
    }
}
