.tab-btn {
    height: auto;
    background-color: var(--color-grey-02);
    color: var(--color-oxford);
    font-size: 14px;
    border-radius: 0;
    padding: 16px 24px;
    border: 0;
    border-top: 3px solid var(--color-grey-02);
    width: auto;
    margin-right: 4px;

    &.disabled {
        cursor: not-allowed;
    }

    &.selected {
        border-top: 3px solid var(--color-mint-green);
        background-color: var(--color-white);
        font-weight: bold;
        color: var(--color-oxford);
        border-color: var(--color-mint-green);
        border-bottom: 0;

        &:hover {
            background-color: var(--color-white);
            cursor: default;
            border-top: 3px solid var(--color-mint-green);
        }
    }

    &:hover {
        background-color: var(--color-grey-02);
        outline: 0;
        border-top: 3px solid var(--color-oslo-grey);
        text-decoration: underline;
    }

    &.large {
        margin-right: 16px;
        font-size: 18px;
        padding: 24px 32px;
        width: 210px;

        @media (--to-medium) {
            padding: 16px 24px;
            font-size: 16px;
            margin-right: 8px;
            width: 175px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}